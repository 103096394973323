import React, { useEffect, useState, useRef } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Container, Row, Col } from "react-bootstrap"
import "./ExpressesDetails.scss"
import ScrollAnimation from "react-animate-on-scroll"
import Breadcrumbs from "@Components/Static/Breadcrumbs/Breadcrumbs"
import { inViewOptions } from "../utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import logoBlack from "../../images/logo.svg"
import NewsBanner from "@Components/Static/NewsBanner/NewsBanner"
import ContactSidebarNews from "@Components/Static/ContactSidebar/ContactSidebarNews"
import OpinionList from "@Components/Static/OpinionList/OpinionList"
import ReactMarkdown from "react-markdown"
import PlayVideo from "../../Components/PlayVideo/PlayVideo"
import RelatedExpress from "@Components/StaticPageTwo/RelatedExpress/RelatedExpress"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import NotFoundPage from "../../pages/404"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import NoImage from "../../images/no-image.png"
const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4,
    },
  },
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1,
    },
  },
}

const ExpressesDetails = props => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setShow(true)
  }

  const GET_EXPRESS_DETAILS = gql`
    query GetExpress($URL: String!) {
      expresses(where: { URL: $URL }) {
        id
        imagetransforms
        Content
        Date
        Designation
        Email
        Iframe
        Meta_Description
        Meta_Title
        Name
        Phone
        Publish
        Video_Url
        URL
        Title
        Image {
          alternativeText
          url
        }
        Tail_Image {
          alternativeText
          url
        }
        Express_Image {
          alternativeText
          url
        }
        File {
          alternativeText
          url
        }
      }
    }
  `
  const { loading, error, data } = useQuery(GET_EXPRESS_DETAILS, {
    variables: { URL: props.slug },
  })
  let shareurl = ""
  if (typeof window !== "undefined") {
    shareurl = window.location.href ? window.location.href : ""
  }

  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const [isClient, setClient] = useState(true)
  const [isPlay, setIsPlay] = useState(false)
  const viewReport = () => {
    setClient(!isClient)
    if (!isClient) {
      executeScroll()
    }
  }
  const playVideoHandler = () => {
    setIsPlay(true)
  }

  if (loading)
    return (
      <div className="static-news gql-loading-screen">
        <Container>
          <div className="gql-loading">
            <img
              className="logo-white loader-logo"
              src={logoBlack}
              alt="logo"
            />
          </div>
        </Container>
      </div>
    )

  return data?.expresses?.length > 0 ? (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <Layout className="blog-page" Alias={"dng-express"}>
                {data?.expresses?.map((Page, i) => {
                  // let processedImages = JSON.stringify({})
                  // if (Page?.imagetransforms?.Image_Transforms) {
                  //   processedImages = Page.imagetransforms.Image_Transforms
                  // }
                  return (
                    <>
                      <SEO
                        title={Page ? Page.Title : ""}
                        description={
                          Page
                            ? "Read about " +
                              Page.Title +
                              " here, subscribe now and stay updated for all latest property news."
                            : ""
                        }
                        image={
                          Page?.Express_Image?.url
                            ? Page.Express_Image.url
                            : null
                        }
                      />
                      <Helmet
                        bodyAttributes={{
                          class: `templates-blog-details-template express-page`,
                        }}
                      />
                      <Breadcrumbs
                        Page={Page?.Title}
                        class="static"
                        alias={"sellers"}
                        type="details-page"
                        typenew="details-page-express"
                      />

                      <div className="static-block">
                        <Container>
                          {/* <Row>
                            <Col xl="7" className="col-xxl-8 col-custom-8">
                              <h1>{Page.Title}</h1>
                              {Page?.Image?.url &&
                                Page?.Name &&
                                Page?.Designation &&
                                Page?.Phone &&
                                Page?.Email && (
                                  <div className="dealer-wrap">
                                    <div className="img-wrap">
                                      {Page?.Image?.url ? (
                                        <ImageTransform
                                          imagesources={Page?.Image?.url}
                                          renderer="srcSet"
                                          imagename="express.Image.detail"
                                          attr={{
                                            alt: Page.Image?.alternativeText,
                                            className: "",
                                          }}
                                          imagetransformresult={processedImages}
                                          id={Page.id}
                                        ></ImageTransform>
                                      ) : (
                                        <img
                                          src={NoImage}
                                          alt={Page.Image?.alternativeText}
                                        />
                                      )}
                                    </div>
                                    <div className="dealer-right">
                                      <span className="dealer-name">
                                        {Page?.Name}
                                      </span>
                                      <span className="dealer-position">
                                        {Page?.Designation}
                                      </span>
                                      <div className="person-contact">
                                        {Page?.Phone && (
                                          <Link
                                            href={"tel:" + Page?.Phone}
                                            className="phone-number"
                                          >
                                            <i className="icon-phone"></i>{" "}
                                            {Page?.Phone}
                                          </Link>
                                        )}
                                        <a
                                          href="javascript:;"
                                          onClick={() => handleShow(i)}
                                          className="mail-text d-none d-xl-flex"
                                        >
                                          <i className="icon-mail"></i>
                                        </a>
                                        <Modal
                                          show={show}
                                          centered={true}
                                          onHide={handleClose}
                                          backdrop="static"
                                          keyboard={false}
                                          dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                        >
                                          <Modal.Header
                                            closeButton
                                            className="contact-close-btn"
                                          >
                                            <Modal.Title className="w-100">
                                              Email to {Page?.Name}
                                            </Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body className="form">
                                            <TeamForm toemail={Page?.Email} />
                                          </Modal.Body>
                                        </Modal>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <div className="dealer-cta d-xl-flex">
                                {Page?.Iframe && (
                                  <div className="btn-wrap">
                                    <a onClick={viewReport} className="btn">
                                      {isClient ? "hide" : "view"} Magazine
                                    </a>
                                  </div>
                                )}
                                {Page?.Video_Url && (
                                  <div className="btn-wrap">
                                    <a
                                      onClick={playVideoHandler}
                                      className="btn outline-btn"
                                    >
                                      <i className="icon-play"></i> play video
                                    </a>
                                  </div>
                                )}
                              </div>
                              <motion.div className="ContentBlock">
                                <ReactMarkdown
                                  source={Page.Content}
                                  allowDangerousHtml
                                />
                              </motion.div>
                              <PlayVideo
                                isOpen={isPlay}
                                stopPlay={setIsPlay}
                                videoId={Page.Video_Url}
                                isAutoPlay={1}
                              />
                            </Col>
                            <Col xl="5" className="col-xxl-4 col-custom-4">
                              {Page?.Image?.url ? (
                                <ImageTransform
                                  imagesources={Page?.Express_Image?.url}
                                  renderer="srcSet"
                                  imagename="express.Express_Image.detail"
                                  attr={{
                                    alt: Page.Express_Image?.alternativeText,
                                    className:
                                      "right-img-view d-none d-md-block",
                                  }}
                                  imagetransformresult={processedImages}
                                  id={Page.id}
                                ></ImageTransform>
                              ) : (
                                <img
                                  src={NoImage}
                                  alt={Page.Express_Image?.alternativeText}
                                />
                              )}
                            </Col>
                          </Row> */}
                          <Row>
                            <Col xl="12" ref={myRef}>
                              {isClient && (
                                <iframe
                                  className=""
                                  src={
                                    Page.Iframe ? Page.Iframe : Page.File?.Url
                                  }
                                  allowfullscreen="true"
                                  allow="fullscreen"
                                  style={{ width: "100%", height: "600px" }}
                                ></iframe>
                              )}
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </>
                  )
                })}
                <SubscribeForm type="Express" />
                <RelatedExpress CurrentNews={data?.expresses[0]?.Title} />
              </Layout>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  ) : (
    <Layout className="blog-page">
      <Router>
        <Switch>
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </Layout>
  )
}

export default ExpressesDetails
